import React from 'react'
import { Box, Layer, Heading } from 'grommet'

const ModalBase = ({
  isOpen = false,
  overflow = 'auto',
  children,
  title,
  width = 'medium',
  full = false,
  onClickOutside = null
}) => {
  if (!isOpen) return null

  return (
    <Layer
      style={{ overflow }}
      animate
      animation='slide'
      modal
      onClickOutside={onClickOutside}
      responsive
      full={full}
    >

      <Box
        direction='column'
        width={full ? '100%' : width}
        background='white'
      >

        <Box
          pad='medium'
          background='light-2'
        >
          <Heading
            level='3'
            margin='xsmall'
          >
            {title}
          </Heading>
        </Box>

        <Box>
          {children}
        </Box>

      </Box>

    </Layer>
  )
}

export default ModalBase
