import React, { useState } from 'react'
import { Box } from 'grommet'
import { dateSummary } from './helpers'

import CalendarioBody from './CalendarioBody'

const Calendario = ({
  entradas = [],
  onClick,
  onDateClick,
  ...others
}) => {
  const [currentDate, setCurrentDate] = useState(new Date())

  const toToday = () => {
    const newDate = new Date()
    setCurrentDate(newDate)
  }

  const before = () => {
    const newDate = new Date(currentDate.getTime())
    newDate.setMonth(newDate.getMonth() - 1)
    setCurrentDate(newDate)
  }

  const next = () => {
    const newDate = new Date(currentDate.getTime())
    newDate.setMonth(newDate.getMonth() + 1)
    setCurrentDate(newDate)
  }

  return (
    <Box
      width='100%'
      border='all'
      {...others}
    >
      <CalendarioBody
        onDateClick={onDateClick}
        onClick={onClick}
        toToday={toToday}
        beforeMonth={before}
        nextMonth={next}
        selected={dateSummary(currentDate)}
        today={dateSummary(new Date())}
        entradas={entradas}
      />
    </Box>
  )
}

export default Calendario
