import React, { useState } from 'react'
import { EventEmitter } from 'fbemitter'
import { Layer, Box, Text, Button } from 'grommet'

const emitter = new EventEmitter()

const AlertConfirmation = ({
  title,
  message,
  onConfirm,
  onCancel,
  destroy
}) => (
  <Layer>
    <Box pad='medium'>
      {title && (
        <Box>
          <Text size='large'>{title}</Text>
        </Box>
      )}
      <Box pad={{ vertical: 'medium' }}>
        <Text size='medium'>{message}</Text>
      </Box>
      <Box direction='row' justify='end'>
        <Button
          label='Confirmar'
          margin={{ right: 'xsmall' }}
          onClick={() => {
            onConfirm && onConfirm()
            destroy()
          }}
        />
        <Button
          label='Cancelar'
          onClick={() => {
            onCancel && onCancel()
            destroy()
          }}
        />
      </Box>
    </Box>
  </Layer>
)

const AlertHandler = () => {
  const [messages, setMessages] = useState([])

  emitter.addListener('message', (data) => {
    const __id = Math.ceil(Math.random() * 2000)
    setMessages([
      ...messages,
      {
        ...data,
        __id,
        destroy: () => {
          setMessages([
            ...messages.filter(m => m.__id !== __id)
          ])
        }
      }
    ])
  })

  return (
    <div>
      {messages.map((message, i) => (
        <AlertConfirmation
          key={i}
          {...message}
        />
      ))}
    </div>
  )
}

export const confirmation = ({
  title,
  message,
  onConfirm,
  onCancel
}) => {
  emitter.emit('message', {
    title,
    message,
    onConfirm,
    onCancel
  })
}

export default AlertHandler
