import React from 'react'
import { Box, Text } from 'grommet'
import styled from 'styled-components'

const PanelBody = styled(Box)`
  border-radius: 8px;
  margin-bottom: 8px;
  background-color: white;
  box-shadow: 0 0 1px rgba(0,0,0,.12), 0 1px 6px rgba(0,0,0,.03), 0 6px 10px -8px rgba(0,0,0,.1);
`

const Panel = ({
  title,
  description,
  children,
  height,
  fillHeight,
  noPad,
  ...others
}) => (
  <Box
    direction='column'
    height={fillHeight && '100%'}
    {...others}
  >

    {(title || description) && (
      <Box
        pad='small'
        style={{ minHeight: '36px' }}
      >
        {title && (
          <Text
            size='small'
            style={{ textTransform: 'uppercase' }}
            weight='bold'
          >
            {title}
          </Text>
        )}

        {description && (
          <Text
            size='small'
            margin={{ top: 'xxsmall' }}
          >
            {description}
          </Text>
        )}

      </Box>
    )}

    <PanelBody
      flex='grow'
      height={height}
      overflow='auto'
      pad={!noPad ? 'medium' : 'none'}
    >
      {children}
    </PanelBody>

  </Box>
)

export default Panel
