import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { Text } from 'grommet'

const BaseTopBarItem = styled(NavLink)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${p => p.theme.global.colors['dark-1']};
    text-decoration: none;
    color: ${p => p.theme.global.colors['light-1']};
    font-weight: normal;
    :hover {
        background-color: ${p => p.theme.global.colors['dark-2']};
    }
    &.active {
      color: ${p => p.theme.global.colors.white};
      font-weight: bold;
    }
    padding: 12px 12px;
`

const TopBarItem = ({ title, to, exact }) => (
  <BaseTopBarItem exact={exact} to={to}>
    <Text size='medium'>{title}</Text>
  </BaseTopBarItem>
)

export default TopBarItem
