import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import createLayout from '../../helpers/createLayout'
import PrivateRoute from '../PrivateRoute'
import Header from '../Header'
import Login from '../../modules/conta/Login'
import AlertHandler from '../../components/elements/Alert'
import useDisconectOnUnauthenticated from './useDisconectOnUnauthenticated'

export const globalMenu = [
  {
    to: '/painel',
    title: 'Painel'
  },
  {
    to: '/projetos',
    title: 'Projetos',
    roles: ['admin_sistema', 'ger_projetos']
  },
  {
    to: '/planejamento',
    title: 'Planejamento',
    roles: ['admin_sistema', 'ger_projetos', 'ges_acompanhamento']
  },
  {
    to: '/financeiro',
    title: 'Financeiro',
    roles: ['admin_sistema', 'ges_lancamentos', 'faturador', 'ges_cargos']
  },
  {
    to: '/sistema',
    title: 'Sistema',
    roles: ['admin_sistema']
  }
]

const Views = (props) => {
  useDisconectOnUnauthenticated(props)

  return (
    <>
      <AlertHandler />
      <PrivateRoute path='/' component={Header} noRedirect />
      <Switch>
        <Route path='/login' component={Login} />
        <PrivateRoute
          path='/painel'
          component={createLayout([
            {
              role: ['colaborador'],
              title: 'Painel',
              path: '/painel',
              component: 'planejamento/BoardAndamento',
              exact: true
            },
            // Quando for cliente o painel será o briefing
            {
              role: ['cliente'],
              title: 'Briefing',
              path: '/painel',
              component: 'briefing/Briefing',
              exact: true
            },
            {
              role: ['colaborador'],
              title: 'Performance',
              path: '/painel/performance',
              component: 'estatisticas/UserPerformance',
              exact: true
            },
            {
              role: ['admin_sistema'],
              title: 'Feedbacks',
              path: '/painel/feedbacks',
              component: 'feedbacks/Painel',
              exact: true
            },
            {
              role: ['cliente'],
              title: 'Enviar feedback',
              path: '/painel/enviar-feedback',
              component: 'feedbacks/NovoFeedback',
              exact: true
            },
            {
              role: ['admin_sistema', 'ges_acompanhamento'],
              title: 'Briefing',
              path: '/painel/briefing',
              component: 'briefing/Briefing',
              exact: true
            }
          ])}
        />
        <PrivateRoute
          path='/projetos'
          component={createLayout([
            {
              role: ['admin_sistema'],
              title: 'Empresas',
              path: '/projetos/empresas',
              component: 'projetos/Empresas',
              exact: true
            },
            {
              role: ['admin_sistema'],
              title: 'Empresa',
              path: '/projetos/empresa/:id',
              component: 'projetos/Empresa',
              exact: true,
              noMenu: true
            },
            {
              role: ['admin_sistema', 'ger_projetos'],
              title: 'Projetos',
              path: '/projetos',
              component: 'projetos/Projetos',
              exact: true
            },
            {
              role: ['admin_sistema', 'ger_projetos'],
              title: 'Projeto',
              path: '/projetos/novo',
              component: 'projetos/NovoProjeto',
              exact: true,
              noMenu: true
            },
            {
              role: ['admin_sistema', 'ger_projetos'],
              title: 'Projeto',
              path: '/projetos/:id',
              component: 'projetos/Projeto',
              exact: true,
              noMenu: true
            }
          ])}
        />
        <PrivateRoute
          path='/planejamento'
          component={createLayout([
            {
              role: ['admin_sistema', 'colaborador'],
              title: 'Calendário',
              path: '/planejamento',
              component: 'planejamento/Acompanhamento',
              exact: true
            },
            {
              role: ['admin_sistema', 'ger_projetos'],
              title: 'Cronograma',
              path: '/planejamento/cronograma',
              component: 'planejamento/Planning',
              exact: true
            },
            {
              role: ['admin_sistema', 'ger_projetos'],
              title: 'Atividades',
              path: '/planejamento/atividades',
              component: 'planejamento/Atividades',
              exact: true
            },
            {
              role: ['admin_sistema', 'ger_projetos'],
              title: 'Estatísticas',
              path: '/planejamento/estatisticas',
              component: 'estatisticas/Painel',
              exact: true
            }
          ])}
        />
        <PrivateRoute
          path='/financeiro'
          component={createLayout([
            {
              role: ['admin_sistema', 'faturador'],
              title: 'Painel',
              path: '/financeiro',
              component: 'financeiro/Painel',
              exact: true
            },
            {
              role: ['admin_sistema', 'faturador'],
              title: 'Faturamento',
              path: '/financeiro/faturamento',
              component: 'financeiro/Faturamentos',
              exact: true
            },
            {
              role: ['admin_sistema', 'ges_lancamentos'],
              title: 'Lançamentos',
              path: '/financeiro/lancamentos',
              component: 'financeiro/Lancamentos',
              exact: true
            },
            {
              role: ['admin_sistema', 'ges_cargos'],
              title: 'Cargos',
              path: '/financeiro/cargos',
              component: 'financeiro/Cargos',
              exact: true
            },
            {
              role: 'admin_sistema',
              title: 'Estatísticas',
              path: '/financeiro/estatisticas',
              component: 'estatisticas/Painel',
              exact: true
            }
          ])}
        />
        <PrivateRoute
          path='/sistema'
          component={createLayout([
            {
              role: 'admin_sistema',
              title: 'Usuários',
              path: '/sistema',
              component: 'sistema/Users',
              exact: true
            }
          ])}
        />
        <Redirect to='/painel' />
      </Switch>
    </>
  )
}

export default Views
