import React, { useContext, useState, useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Context } from '../../../common/Session'
import { LOGIN_QUERY, RECUPERAR_CONTA } from './queries'
import LoginComponent from './LoginComponent'

export default ({
  history
}) => {
  const session = useContext(Context)
  const [sendLogin, { loading }] = useMutation(LOGIN_QUERY)
  const [sendRecuperar] = useMutation(RECUPERAR_CONTA)
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)

  const isAuth = session.isAuthenticated()

  useEffect(() => {
    if (isAuth) {
      history.push('/painel')
    }
  }, [isAuth, history])

  const onEntrar = () => {
    setError(null)
    sendLogin({
      variables: {
        input: { login, password }
      }
    })
      .then(({ data }) => {
        session.register(data.obterTokenAutenticacao)
      })
      .catch(err => {
        setError(err.graphQLErrors
          ? err.graphQLErrors[0].message
          : 'Erro inesperado no servidor.')
      })
  }

  const handleEsqueciSenha = () => {
    if (!login) {
      return setError('É necessário preencher o e-mail.')
    }
    if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(login)) {
      return setError('E-mail inválido')
    }
    sendRecuperar({
      variables: {
        email: login
      }
    })
      .then(() => {
        setError('Você receberá um link no seu e-mail.')
      })
      .catch(() => {
        setError('Erro inesperado.')
      })
  }

  return (
    <LoginComponent
      loading={loading}
      onEntrar={onEntrar}
      error={error}
      login={login}
      onLoginChange={e => setLogin(e.target.value)}
      password={password}
      onPasswordChange={e => setPassword(e.target.value)}
      disconnectedUnauthorized={history.location.state && history.location.state.code === 'unauthenticated'}
      handleEsqueciSenha={handleEsqueciSenha}
    />
  )
}
