import React from 'react'
import { Box } from 'grommet'

import CalendarioHeader from './CalendarioHeader'
import CalendarioDate from './CalendarioDate'

const CalendarioBody = (props) => {
  let index = 0

  const rows = [
    <CalendarioHeader key='header' {...props} />
  ]

  for (let weekIndex = 0; weekIndex < 6; weekIndex++) {
    const days = []

    for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
      const reference = props.selected

      const first = new Date(reference.date.getTime())
      first.setDate(1)
      const firstWeekPosition = first.getDay()

      const newDate = new Date(reference.date.getTime())
      newDate.setDate(index - firstWeekPosition + 1)

      days.push((
        <CalendarioDate
          key={dayIndex}
          {...props}
          onDateClick={(d) => {
            props.onDateClick && props.onDateClick(newDate)
          }}
          current={{
            date: newDate,
            weekMonth: weekIndex,
            dayWeek: dayIndex,
            sameMonth: reference.date.getMonth() === newDate.getMonth(),
            index
          }}
          entradas={props.entradas.filter(e => (
            e.date.getDate() === newDate.getDate() &&
            e.date.getMonth() === newDate.getMonth() &&
            e.date.getYear() === newDate.getYear()
          ))}
        />
      ))
      index++
    }

    rows.push((
      <Box
        key={weekIndex}
        justify='stretch'
        alignContent='center'
        direction='row'
        height={{ min: 'xsmall' }}
        width='100%'
      >
        {days}
      </Box>
    ))
  }

  return rows
}

export default CalendarioBody
