import { HttpLink } from 'apollo-link-http'
import { ApolloLink } from 'apollo-link'
import { loadSession } from './Session'
import { onError } from 'apollo-link-error'
import { callUnauthenticatedError } from './events'

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_ENDPOINT || '/graphql'
})

const authMiddleware = new ApolloLink((operation, forward) => {
  const session = loadSession()
  const headers = {}
  if (session.token) {
    headers.authorization = session.token
  }
  operation.setContext({ headers })
  return forward(operation)
})

const errorMiddleware = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      if (err.extensions && err.extensions.code) {
        switch (err.extensions.code) {
          case 'UNAUTHENTICATED':
            callUnauthenticatedError()
            break
        }
      }
    }
  }
})

const mergedLink = ApolloLink.from([
  authMiddleware,
  httpLink
])

export default errorMiddleware.concat(mergedLink)
