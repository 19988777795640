import styled from 'styled-components'

export const ActionBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 4px;
`

export const ActionBarItem = styled.div`
  padding: 0 8px;
`
