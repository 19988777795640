import React from 'react'
import styled from 'styled-components'

const Icon = styled.div`
  font-size: 30px;
  color: gray;
  cursor: pointer;
  
  :after {
      content: "+";
      width: 20px;
      height: 20px;
  }
`

const Add = ({ size, fontSize }) => (
  <Icon size={size} fontSize={fontSize} />
)

export default Add
