import React, { useState, createContext } from 'react'

export const Context = createContext({})

export const Provider = Context.Provider

export const Consumer = Context.Consumer

const saveSession = (session) => {
  localStorage.setItem('rm:session', JSON.stringify(session))
}

export const loadSession = () => {
  const data = localStorage.getItem('rm:session')
  if (!data) return {}
  return JSON.parse(data)
}

export default ({ children }) => {
  const [session, setSession] = useState({
    auth: loadSession()
  })

  session.isAuthenticated = () => {
    return session.auth && session.auth.uid
  }

  session.hasRole = () => {
    return true
  }

  session.register = (loginResult) => {
    saveSession(loginResult)
    setSession({
      ...session,
      auth: loginResult
    })
  }

  session.disconnect = () => {
    setSession({})
    localStorage.removeItem('rm:session')
  }

  session.setSession = (props) => {
    setSession({
      ...session,
      ...props
    })
  }

  return (
    <Provider value={session}>
      {children(session)}
    </Provider>
  )
}
