import React, { useState } from 'react'
import ModalBase from './ModalBase'

export default ({
  trigger,
  children,
  ...others
}) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      {trigger({ setOpen })}
      <ModalBase
        isOpen={open}
        {...others}
      >
        {children({ setOpen })}
      </ModalBase>
    </>
  )
}
