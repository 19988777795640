import React from 'react'
import { Box } from 'grommet'

export default ({
  children
}) => (
  <Box
    justify='end'
    background='dark-1'
    direction='row'
    pad={{ horizontal: 'large' }}
  >
    {children}
  </Box>
)
