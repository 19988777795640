import { useEffect } from 'react'
import { emitter } from '../../common/events'
import { useHistory } from 'react-router-dom'

export default ({ session }) => {
  const history = useHistory()
  useEffect(() => {
    const token = emitter.addListener('unauthenticated', () => {
      if (history.location.pathname !== '/login') {
        session.disconnect()
        history.push('/login', { code: 'unauthenticated' })
      }
    })
    return () => {
      token.remove()
    }
  })
}
