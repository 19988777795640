import React from 'react'
import styled from 'styled-components'
import { Box } from 'grommet'

import logo from './logo.png'

const Image = styled.img`
    height: 70px;
`
const Link = ({ title, ...others }) => (
  <Box
    align='center'
    justify='center'
    pad={{ horizontal: 'small' }}
  >
    <Image src={logo} />
  </Box>
)

export default Link
