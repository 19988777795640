import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'

export const anoAtual = () => {
  const date = new Date()
  return date.getFullYear()
}

export const apartirDeHoje = (somar) => {
  const date = new Date()
  date.setDate(date.getDate() + somar)
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(1)
  return date
}

export const ateHoje = () => {
  const date = new Date()
  date.setHours(23)
  date.setMinutes(59)
  date.setSeconds(59)
  date.setMilliseconds(59)
  return date
}

export const baseDate = (dateString) => {
  const date = new Date(dateString)
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  return `${year}-${month}-${day}`
}

export const cast = date => {
  if (!date) return ''
  return dayjs(date).format('YYYY-MM-DD')
}

export const castWithTime = date => {
  if (!date) return ''
  return dayjs(date).format('YYYY-MM-DDTHH:mm')
}

export const dateOrDefault = (value, base) => {
  try {
    if (!value) { return base }
    if (typeof value === 'string') { return new Date(value) }
    if (typeof value === 'string' && value.length < 6) { return new Date(value) }
    return value
  } catch (error) {
    return base
  }
}

export const formatDateTime = date => {
  if (!date) return ''
  return dayjs(date).format('DD/MM/YYYY HH:mm')
}

export const formatDate = (date) => {
  if (!date) return ''
  return dayjs(date).format('DD/MM/YYYY')
}

export const clone = (date) => {
  return new Date(date.getTime())
}

export const createYearList = (startIn) => {
  const years = []
  const to = (new Date()).getFullYear()
  for (let i = startIn; i <= to; i++) {
    years.push(i.toString())
  }
  return years.reverse()
}

export const monthList = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
]
