import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { Text } from 'grommet'

const BaseLink = styled(NavLink)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${p => p.theme.global.colors[p.inverted ? 'dark-1' : 'white']};
    padding: 0 12px;
    text-decoration: none;
    color: ${p => p.theme.global.colors[p.inverted ? 'white' : 'dark-1']};
    :hover {
        background-color: ${p => p.theme.global.colors[p.inverted ? 'dark-2' : 'light-1']};
    }
    &.active {
      color: ${p => p.theme.global.colors.brand};
      font-weight: bold;
    }
`

const Link = ({ title, to, inverted }) => (
  <BaseLink to={to} inverted={inverted}>
    <Text
      style={{ textTransform: 'uppercase' }}
      size='small'
    >
      {title}
    </Text>
  </BaseLink>
)

export default Link
