import styled from 'styled-components'
import { FormField } from 'grommet'

const FormFieldRow = styled(FormField)`
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`

export default FormFieldRow
