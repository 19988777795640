import { EventEmitter } from 'fbemitter'

export const emitter = new EventEmitter()

export const callUnauthenticatedError = () => {
  emitter.emit('unauthenticated')
}

export const handleUnauthenticatedError = (cb) => {
  emitter.addListener('unauthenticated', cb)
}
