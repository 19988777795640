import React from 'react'
import { Box, Form, FormField, Text } from 'grommet'
import { ExButton } from '../../../components/elements'

const Error = ({ message }) => (
  <Box pad={{ bottom: 'small' }}>
    <Text
      color='status-critical'
      size='small'
    >
      {message}
    </Text>
  </Box>
)

export default ({
  loading,
  onEntrar,
  error,
  login,
  onLoginChange,
  password,
  onPasswordChange,
  disconnectedUnauthorized,
  handleEsqueciSenha
}) => (
  <Box
    background={{ image: 'url(/login-bg.jpg)' }}
    style={{
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    }}
    height='100%'
    width='100%'
    justify='center'
    align='center'
  >
    <Box
      background='white'
      width={{ min: '320px', max: '400px' }}
      pad='medium'
    >
      <Box
        align='center'
        justify='center'
        alignContent='center'
        pad='medium'
      >
        <img
          src='/logo.png'
          width='200'
          style={{
            width: '100%',
            maxWidth: '200px'
          }}
        />
      </Box>
      <Form onSubmit={onEntrar}>
        <FormField
          type='text'
          name='email'
          label='E-mail'
          value={login}
          onChange={onLoginChange}
        />
        <FormField
          type='password'
          name='password'
          label='Senha'
          value={password}
          onChange={onPasswordChange}
        />
        {error && (
          <Error message={error} />
        )}
        {disconnectedUnauthorized && (
          <Error message='Sua autenticação não é mais válida, faça login novamente.' />
        )}
        <Box>
          <ExButton
            loading={loading}
            type='submit'
            primary
            label='Entrar'
            margin={{ bottom: 'small' }}
          />
          <ExButton
            secondary
            type='button'
            label='Esqueci minha senha'
            onClick={handleEsqueciSenha}
          />
        </Box>
      </Form>
    </Box>
  </Box>
)
