import gql from 'graphql-tag'

export const LOGIN_QUERY = gql`
  mutation login($input: InputObterTokenAutenticacao!) {
    obterTokenAutenticacao(input: $input) {
      token
      uid
      nome
      email
      permissoes
    }
  }
`

export const RECUPERAR_CONTA = gql`
  mutation obterCodigoRecuperacao($email: String!) {
    obterCodigoRecuperacao(email: $email)
  }
`
