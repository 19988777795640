import React from 'react'
import styled from 'styled-components'

const Icon = styled.div`
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;
    
    :before {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 2px solid gray;
    }
    :after {
        content: "";
        position: absolute;
        width: 2px;
        height: 12px;
        background-color: gray;
        transform: rotate(-45deg);
        left: 14px;
        top: 9px;
    }
`

const Search = () => (
  <Icon />
)

export default Search
