import React, { useState } from 'react'
import { Box, Button } from 'grommet'
import { Logout } from 'grommet-icons'
import styled from 'styled-components'
import Brand from './Brand'
import MenuItem from './MenuItem'
import checkRole from '../../helpers/checkRole'

import { globalMenu } from '../App/Views'

const EyedButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  cursor: pointer;
`

const FullModeToggle = ({ onClick }) => (
  <EyedButton onClick={onClick} />
)

const Header = ({
  history,
  session
}) => {
  const [fullMode, setFullMode] = useState(false)

  const logout = () => {
    session.disconnect()
    history.push('/login')
  }

  return (
    <>
      <FullModeToggle
        hide={fullMode}
        onClick={() => setFullMode(!fullMode)}
      />
      {!fullMode && (
        <Box
          height={{ min: 'xsmall' }}
          direction='row'
          justify='between'
          background={{ color: 'white' }}
        >
          <Brand />
          <Box
            margin={{ left: 'auto' }}
            direction='row'
            justify='start'
            pad={{ horizontal: 'medium' }}
          >
            {globalMenu.map((props, i) => {
              const r = checkRole(session, props.roles)
              if (!r) return null
              return (
                <MenuItem
                  key={i}
                  {...props}
                />
              )
            })}
            <Box
              justify='center'
              alignContent='center'
              pad={{ horizontal: 'medium' }}
            >
              <Button onClick={logout} icon={<Logout />} />
            </Box>

          </Box>
        </Box>
      )}
    </>
  )
}

export default Header
