import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { Context } from '../common/Session'

const PrivateRoute = ({ component, noRedirect, ...others }) => {
  const session = useContext(Context)

  const Component = component

  return (
    <Route
      {...others}
      render={(routeProps) => {
        if (!session.isAuthenticated()) {
          return !noRedirect && <Redirect to='/login' />
        } else {
          return (
            <Component
              {...routeProps}
              session={session}
            />
          )
        }
      }}
    />
  )
}

export default PrivateRoute
