import React from 'react'
import { Box, Text } from 'grommet'
import { FormClock, FormCheckmark, FormUpload } from 'grommet-icons'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'

const HoverText = styled(Text)`
  cursor: pointer;
  :hover {
    font-weight: bold;
  }
`

const isCurrentDate = (today, current) => (
  current.date.getDate() === today.date.getDate() &&
  current.date.getMonth() === today.date.getMonth() &&
  current.date.getYear() === today.date.getYear()
)

const getDayColor = (today, current) => {
  if (isCurrentDate(today, current)) {
    return 'brand'
  } else if (!current.sameMonth) {
    return 'light-5'
  } else {
    return 'black'
  }
}

const CalendarioDate = ({
  today,
  current,
  entradas,
  onClick,
  onDateClick
}) => {
  return (
    <Box
      style={{ flex: '1 0' }}
      background='white'
      border='bottom'
      direction='column'
    >
      <Box pad='small'>
        <HoverText
          weight={isCurrentDate(today, current) ? 'bold' : 'normal'}
          color={getDayColor(today, current)}
          onClick={() => onDateClick(current.date.getDate())}
        >
          {current.date.getDate()}
        </HoverText>
      </Box>
      {entradas.map((e, i) => (
        <Box
          key={i}
          margin='xxsmall'
          background={e.background || 'light-1'}
          onClick={() => onClick && onClick(e)}
          style={{
            cursor: 'pointer',
            position: 'relative'
          }}
        >
          {(e.andamento || e.verificada || e.cadastrada) && (
            <Box
              pad={{ top: 'xxsmall', horizontal: 'xsmall' }}
              direction='row'
              justify='end'
            >
              <ReactTooltip id={'label_andamento_' + i} effect='solid'>Andamento</ReactTooltip>
              <ReactTooltip id={'label_verificada_' + i} effect='solid'>Verificada</ReactTooltip>
              <ReactTooltip id={'label_cadastrada_' + i} effect='solid'>Cadastrada</ReactTooltip>
              {e.andamento && (
                <Box data-tip data-for={'label_andamento_' + i} style={{ marginRight: 'auto' }}>
                  <FormClock style={{ width: '20px' }} color='white' />
                </Box>
              )}
              {e.verificada && (
                <Box data-tip data-for={'label_verificada_' + i}>
                  <FormCheckmark style={{ width: '20px' }} color='white' />
                </Box>
              )}
              {e.cadastrada && (
                <Box data-tip data-for={'label_cadastrada_' + i}>
                  <FormUpload style={{ width: '20px' }} color='white' />
                </Box>
              )}
            </Box>
          )}
          <Box
            pad={{
              top: (e.andamento || e.verificada || e.cadastrada) ? 'xxsmall' : 'small',
              bottom: 'small',
              horizontal: 'small'
            }}
          >
            {e.tag && <Text size='small'>{e.tag}</Text>}
            <Text size='medium' weight='bold'>{typeof e.title === 'string' ? e.title : e.title()}</Text>
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default CalendarioDate
