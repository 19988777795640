import React, { useState } from 'react'
import { Box, Button } from 'grommet'
import posed from 'react-pose'
import { Refresh, RadialSelected } from 'grommet-icons'

const PressableButton = posed(Button)({
  pressable: true,
  init: { scale: 1 },
  press: { scale: 0.8 }
})

const DataRefresh = ({
  refetch,
  startPolling,
  stopPolling,
  networkStatus,
  margin
}) => {
  const [isPooling, setPolling] = useState(false)

  const update = () => {
    refetch()
  }

  const tooglePolling = () => {
    if (isPooling) {
      setPolling(false)
      stopPolling()
    } else {
      setPolling(true)
      startPolling(10000)
    }
  }

  const poolingColor = () => {
    if (isPooling) {
      return networkStatus === 6
        ? 'orange'
        : 'green'
    } else {
      return 'grey'
    }
  }

  return (
    <Box
      width='100%'
      direction='row'
      justify='end'
      margin={margin}
      align='center'
    >
      <PressableButton
        onClick={update}
        disabled={networkStatus === 1}
        style={{ paddingTop: '6px' }}
        data-tip='Atualizar dados'
      >
        <Refresh
          color={networkStatus === 4 ? 'orange' : 'green'}
        />
      </PressableButton>
      {/* <PressableButton
        margin={{ horizontal: 'small' }}
        onClick={tooglePolling}
        disabled={networkStatus === 1}
        style={{ paddingTop: '6px' }}
      >
        <RadialSelected
          color={poolingColor()}
        />
      </PressableButton> */}
    </Box>
  )
}

export default DataRefresh
