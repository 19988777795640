import React from 'react'
import { Layer, Box, Text } from 'grommet'

const Modal = ({
  title,
  description,
  width = 'large',
  isOpen,
  component,
  ...others
}) => {
  const Component = component

  if (!isOpen) return null

  return (
    <Layer>
      <Box
        width={width}
        background='white'
      >
        {(title || description) && (
          <Box
            pad='medium'
            background='light-2'
          >
            {title && (
              <Box>
                <Text size='medium'>{title}</Text>
              </Box>
            )}
            {description && (
              <Box>
                <Text size='small'>{description}</Text>
              </Box>
            )}
          </Box>
        )}
        <Box pad='medium'>
          <Component {...others} />
        </Box>
      </Box>
    </Layer>
  )
}

export default Modal
