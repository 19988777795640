import React from 'react'
import { Button } from 'grommet'

export default ({ loading, ...others }) => {
  if (loading) {
    return (
      <Button
        {...others}
        label='Carregando...'
        disabled
      />
    )
  }

  return (
    <Button
      {...others}
    />
  )
}
