import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { persistCache } from 'apollo-cache-persist'
import link from './client_link'

const cache = new InMemoryCache({
  cacheRedirects: {
    // Query: {
    //   atividade: (_, args, { getCacheKey }) => {
    //     const d = getCacheKey({ __typename: 'Atividade', id: args.id })
    //     console.debug(d)
    //     return d
    //   }
    // }
  }
})

export default async () => {
  try {
    await persistCache({
      cache,
      storage: window.localStorage,
      key: 'app-data',
      maxSize: 1048576 * 6 // (8 MB)
    })
  } catch (err) {
    console.debug('Error loading app data', err)
  }

  return new ApolloClient({
    link,
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        // fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
      },
      query: {
        fetchPolicy: 'cache-and-network',
        // fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
      }
    }
  })
}
