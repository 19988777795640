import React from 'react'
import { Box, Text, Button } from 'grommet'
import { Calendar, FormNext, FormPrevious } from 'grommet-icons'
import { monthList } from '../../../helpers/dates'

const dayNames = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado']

const CalendarioHeader = ({
  selected,
  toToday,
  nextMonth,
  beforeMonth
}) => (
  <Box width='100%'>
    <Box
      pad='small'
      direction='row'
      justify='end'
      alignContent='center'
    >
      <Text margin='small'>
        {monthList[selected.date.getMonth()]} de {selected.date.getYear() + 1900}
      </Text>
      <Button
        onClick={toToday}
        margin={{ left: 'large', right: 'small' }}
        icon={<Calendar />}
        data-tip='Hoje'
      />
      <Button
        onClick={beforeMonth}
        margin={{ left: 'small', right: 'small' }}
        icon={<FormPrevious />}
        data-tip='Mês anterior'
      />
      <Button
        onClick={nextMonth}
        icon={<FormNext />}
        data-tip='Próximo mês'
      />
    </Box>
    <Box
      justify='stretch'
      alignContent='center'
      direction='row'
      height={{ min: 'xxsmall' }}
      width='100%'
    >
      {dayNames.map((day, index) => (
        <Box
          justify='center'
          alignContent='center'
          key={index}
          style={{ flex: '1 0' }}
          border='bottom'
        >
          <Text textAlign='center'>{day}</Text>
        </Box>
      ))}
    </Box>
  </Box>
)

export default CalendarioHeader
