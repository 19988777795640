
export default (session, role) => {
  const roles = session.auth ? session.auth.permissoes : []

  if (!role) return true

  if (Array.isArray(role)) {
    return role.reduce((acc, c) => {
      if (!acc) {
        return roles.indexOf(c) > -1
      }
      return acc
    }, false)
  } else if (typeof role === 'string') {
    return roles.indexOf(role) > -1
  }

  return false
}
