import React from 'react'
import { Box } from 'grommet'
import ReactTooltip from 'react-tooltip'

export default ({
  children
}) => (
  <Box
    direction='column'
    height='100%'
    width='100%'
    justify='stretch'
    // background='light-1'
  >
    <ReactTooltip
      place='top'
      effect='solid'
    />
    {children}
  </Box>
)
