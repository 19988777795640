import React, { useState } from 'react'
import { Box, Button, Text } from 'grommet'
import styled from 'styled-components'

const PanelSectionContent = styled(Box)`
  max-height: ${props => props.expanded ? 'auto' : '0px'};
  overflow: hidden;
`

const PanelSection = ({
  noShowButtom,
  title = '---',
  description,
  defaultExpanded,
  children,
  value = null,
  onChange
}) => {
  let expanded

  const [control, setControl] = useState(defaultExpanded || false)

  const toggle = () => {
    onChange && onChange(!expanded)
    setControl(!control)
  }

  if (value !== null) {
    expanded = value
  } else {
    expanded = control
  }

  return (
    <Box
      border='bottom'
    >
      <Box
        direction='row'
        align='center'
      >
        <Box
          flex='grow'
          pad='medium'
        >
          <Text size='medium'>{title}</Text>
          {description && <Text size='small'>{description}</Text>}
        </Box>
        {!noShowButtom && (
          <Box>
            <Button
              margin='medium'
              size='small'
              onClick={toggle}
            >
              <Text
                size='small'
                style={{ textTransform: 'uppercase' }}
              >
                {expanded ? 'Fechar' : 'Expandir'}
              </Text>
            </Button>
          </Box>
        )}
      </Box>
      <PanelSectionContent
        expanded={expanded}
      >
        <Box
          pad={{
            horizontal: 'medium',
            vertical: 'medium'
          }}
          background='light-1'
        >
          {children}
        </Box>

      </PanelSectionContent>
    </Box>
  )
}

export default PanelSection
