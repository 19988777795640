import React from 'react'
import { Box, Text } from 'grommet'

export default ({
  label,
  value
}) => {
  if (!value) return null

  return (
    <Box margin={{ vertical: '2px' }}>
      <Box pad={{
        horizontal: '4px',
        top: '4px',
        bottom: '1px'
      }}
      >
        <Text
          size='xsmall'
        >
          {label}
        </Text>
      </Box>
      <Box
        pad={{
          horizontal: '4px',
          top: '1px',
          bottom: '4px'
        }}
      >
        <Text>{value}</Text>
      </Box>
    </Box>
  )
}
