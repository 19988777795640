import React from 'react'
import { ResponsiveContext, Box } from 'grommet'

export default ({
  children
}) => (
  <ResponsiveContext.Consumer>
    {(size) => {
      if (
        size === 'xsmall' ||
        size === 'small' ||
        size === 'medium') {
        // TODO: implementar versão mobile
        return null
      }

      return (
        <Box
          background='white'
          direction='column'
          width='300px'
          height='100%'
          pad={{ vertical: 'medium' }}
          style={{ minWidth: '300px', maxWidth: '300px' }}
        >
          <Box pad={{ horizontal: 'medium', vertical: 'medium' }}>
            {children}
          </Box>
        </Box>
      )
    }}

  </ResponsiveContext.Consumer>

)
