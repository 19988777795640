import React, { useState, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/react-hooks'
import { Grommet } from 'grommet'
import getClient from '../../common/client'
import theme from '../../common/theme.json'
import Session from '../../common/Session'

import Views from './Views'

export default () => {
  const [client, setClient] = useState(null)

  useEffect(() => {
    getClient().then(c => {
      setClient(c)
    })
  }, [true])

  if (!client) return null

  return (
    <Grommet
      theme={theme}
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        overflow: 'hidden'
      }}
    >
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Session>
            {session => <Views session={session} />}
          </Session>
        </BrowserRouter>
      </ApolloProvider>
    </Grommet>
  )
}
