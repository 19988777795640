import React from 'react'
import { Box } from 'grommet'

export default ({
  loading,
  children
}) => (
  <Box
    direction='column'
    pad={{ horizontal: 'medium', vertical: 'small' }}
    overflow='auto'
    height='100%'
    style={{
      display: 'block',
      flexGrow: '1'
    }}
    background={{ color: '#f2f4f7' }}
  >
    {children}
  </Box>
)
