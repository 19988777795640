import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import lodable from '@loadable/component'
import { Page, Layout, TopBar, TopBarItem } from '../components/elements'
import checkRole from './checkRole'

export default (routes = []) => {
  const PivotPage = (props) => {
    const filtered = routes
      .filter(r => checkRole(props.session, r.role))
    return (
      <Page>
        <Layout>
          <TopBar>
            {filtered
              .filter(r => !r.noMenu)
              .map((route, i) => (
                <TopBarItem
                  {...props}
                  key={i}
                  title={route.title}
                  to={route.path}
                  exact={route.exact}
                />
              ))}
          </TopBar>
          <Switch>
            {filtered
              .map((route, i) => (
                <Route
                  {...props}
                  key={i}
                  path={route.path}
                  exact={route.exact}
                  component={lodable(() => import('../modules/' + route.component))}
                />
              ))}
            <Redirect to='/painel' />
          </Switch>
        </Layout>
      </Page>
    )
  }

  return PivotPage
}
