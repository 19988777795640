import React from 'react'
import { Box } from 'grommet'

export default ({
  children
}) => (
  <Box
    direction='row'
    height='100%'
    style={{ flexGrow: '1' }}
  >
    {children}
  </Box>
)
