import styled from 'styled-components'

const Table = styled.table`
  text-align: left;
  border-collapse: collapse;
  width: 100%;
  white-space: nowrap;
  
  thead {
    th {
      color: rgba(0,0,0,.54);
      font-size: 12px;
      font-weight: 700;
      padding-bottom: 25px;
      padding-left: 10px;
    }
  } 
  tbody td {
    border-top: 1px rgba(0,0,0,.12) solid;
    font-size: 13px;
    color: rgba(0,0,0,.87);
    padding: 10px 10px;
  }
`

export default Table
